import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../actions/searchActions";
import { Table, Button, Icon, Row, Col, message, Dropdown, Menu, Tooltip, Progress, Skeleton, Tag, Drawer } from "antd";
import ResultsTableCard from "../ResultsTableCard";
import ResultsListCards from "../ResultsListCards";
import * as meta from "../../actions/queryActions";
import ResultsDrawer from "../ResultsDrawer";

export class ResultsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRowKeys: [],
			selectedItems: [],
			resultCount: 200,
			pageSize: 100,
			expandedRowKeys: [],
			expandStatus: false,
			view: "table",
			export: false,
			profile: null,
			visible: false
		};
	}

	componentDidMount() {
		this.props.meta.getQuery(this.props.match.params.workbookId, this.props.match.params.queryId);
		this.props.actions.getSearch(this.props.match.params.workbookId, this.props.match.params.queryId);
	}

	handleMenuClick(e) {
		this.setState({ resultCount: parseInt(e.key) });
	}

	enrichMessage() {
		message.loading("Enrichment in progress");
	}

	exportMessage(count) {
		message.loading("CSV is being created.", 0.5).then(() => message.success(`${count} rows downloaded`));
	}

	onSelectChange = (selectedRowKeys, selected) => {
		this.setState({
			selectedRowKeys: selectedRowKeys,
			selectedItems: selected
		});
	};

	onProfileSelect(id) {
		const index = this.state.selectedRowKeys.indexOf(id);
		const selected = this.state.selectedRowKeys;
		if (index === -1) {
			selected.push(id);
			this.setState({ selectedRowKeys: selected });
		} else if (index > -1) {
			selected.splice(index, 1);
			this.setState({ selectedRowKeys: selected });
		}
	}

	onEnrich = () => {
		this.enrichMessage();
		this.props.actions.enrichStatus();
		this.props.actions.enrichSearch(this.props.resultSetId, this.state.selectedRowKeys);
		this.setState({ export: true, selectedRowKeys: [], selectedItems: [] });
	};

	onExport = () => {
		this.exportMessage(this.state.selectedRowKeys.length);
		this.props.actions.exportSearch(this.props.resultSetId, this.state.selectedRowKeys);
	};

	CreateCard(data) {
		return <ResultsTableCard data={data} />;
	}

	onTableRowExpand(expanded, record) {
		const keys = [];
		if (expanded) {
			keys.push(record.id);
		}
		this.setState({ expandedRowKeys: keys });
	}

	ExpandAction(results) {
		const keys = [];
		results.forEach((x) => {
			keys.push(x.id);
		});
		if (this.state.expandedRowKeys.length === keys.length) {
			this.setState({ expandedRowKeys: [], expandStatus: false });
		} else if (this.state.expandedRowKeys.length !== keys.length) {
			this.setState({ expandedRowKeys: keys, expandStatus: true });
		}
	}

	createSocial(link, label) {
		if (link.includes("redacted") === true) {
			return (
				<Icon
					type={label}
					theme={label === "twitter" ? "outlined" : "filled"}
					className={`icon__${label}--disabled`}
				/>
			);
		} else if (link.includes("redacted") === false) {
			return (
				<a href={link} target="_blank">
					<Icon
						type={label}
						theme={label === "twitter" ? "outlined" : "filled"}
						className={`icon__${label}
          }`}
					/>
				</a>
			);
		}
	}

	goBack() {
		this.props.history.goBack();
	}

	UpdateView(name) {
		this.setState({ view: name });
	}

	checkMatch(value) {
		const stats = this.props.preview;
		if (value < stats.maxRelevancy && value > stats.meanRelevancy) {
			return (
				<Tag
					style={{
						background: "rgba(89, 165, 113, 0.2)",
						border: "1px solid rgba(89, 165, 113, 1)",
						color: "rgba(89, 165, 113, 1)"
					}}
				>
					Excellent
				</Tag>
			);
		} else if (value <= stats.meanRelevancy && value > stats.minRelevancy) {
			return (
				<Tag
					style={{
						background: "rgba(67, 177, 117, 0.2)",
						border: "1px solid rgba(67, 177, 117, 1)",
						color: "rgba(67, 177, 117, 1)"
					}}
				>
					Good
				</Tag>
			);
		} else if (value <= stats.minRelevancy) {
			return (
				<Tag
					style={{
						background: "rgba(247, 225, 132, 0.2)",
						border: "1px solid #F4A621",
						color: "#F4A621"
					}}
				>
					Okay
				</Tag>
			);
		} else if (value === stats.maxRelevancy) {
			return <Tag color="green">Top Match</Tag>;
		}
	}

	createProfile(data) {
		this.setState({ profile: data });
		if (this.state.visible === true) {
			this.setState({ visible: false });
		} else {
			this.setState({ visible: true });
		}
	}

	switchProfile(tag) {
		if (tag === "next") {
			const index = this.props.results.indexOf(this.state.profile);
			if (index !== this.props.results.length - 1) {
				this.setState({ profile: this.props.results[index + 1] });
			}
		} else if (tag === "prev") {
			const index = this.props.results.indexOf(this.state.profile);
			if (index !== 0) {
				this.setState({ profile: this.props.results[index - 1] });
			}
		}
	}

	onClose() {
		if (this.state.visible === true) {
			this.setState({ visible: false });
		} else {
			this.setState({ visible: true });
		}
	}

	onShowSizeChange(current, pageSize) {}

	render() {
		const { hasStatus } = this.props;
		const { selectedRowKeys, selectedItems, expandedRowKeys } = this.state;

		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange
		};
		const columns = [
			{
				title: "Full Name",
				width: 200,
				key: "name",
				render: (x) => {
					if (x.firstName.includes("redacted")) {
						return (
							<span>
								<Skeleton
									style={{ marginRight: "1rem" }}
									paragraph={false}
									title={<span className="redacted__item">{x.firstName}</span>}
									active={true}
								>
									{x.firstName} {x.lastName}
								</Skeleton>
							</span>
						);
					} else {
						return (
							<span>
								{x.firstName} {x.lastName}
							</span>
						);
					}
				}
			},
			{
				title: "Match",
				key: "relevance",
				align: "center",
				width: 150,
				render: (x) => {
					return this.checkMatch(x.relevancy);
				}
			},
			{
				title: "Experience",
				key: "careerLength",
				align: "center",
				width: 150,
				render: (x) => {
					return <span>{x.careerLength}</span>;
				}
			},
			{
				title: "Jobs",
				key: "careerCount",
				align: "center",
				width: 150,
				render: (x) => {
					return <span>{x.careerCount}</span>;
				}
			},
			{
				title: "Score",
				key: "score",
				align: "center",
				width: 150,
				render: (x) => {
					return <Progress width={50} type="circle" percent={Math.floor(x.score)} />;
				}
			},
			{
				title: "",
				key: "",
				align: "center",
				width: 150,
				render: (x) => {
					return (
						<span
							style={{
								color: "#32b9ff",
								fontWeight: "bold",
								cursor: "pointer"
							}}
							onClick={() => this.createProfile(x)}
						>
							View Profile
						</span>
					);
				}
			}
		];

		return (
			<Row
				style={{
					marginTop: "2rem",
					marginBottom: "1rem",
					marginLeft: "2rem",
					marginRight: "2rem",
					minHeight: "85vh!important"
				}}
			>
				<Row style={{ background: "#32b9ff", marginBottom: "2rem" }}>
					<Col span={5} style={{ paddingLeft: 40, paddingTop: 15 }}>
						<h3 style={{ color: "white", fontWeight: "bold" }}>DISCOVER</h3>
					</Col>
					<Col span={19}>
						<Button
							size="small"
							disabled={this.state.export === true ? false : true}
							onClick={() => this.onExport()}
							style={{
								float: "right",
								marginTop: "1.5rem",
								marginLeft: "1rem",
								marginRight: "2rem"
							}}
						>
							<Icon type="export" />
							Export Selected{" "}
						</Button>
						{selectedRowKeys.length > 0 ? (
							<Button
								size="small"
								style={{
									float: "right",
									marginTop: "1.5rem",
									marginLeft: "1rem",
									marginRight: "0rem"
								}}
								onClick={() => this.onEnrich()}
							>
								<Icon type="sync" />
								<span>Enrich Data</span>
							</Button>
						) : (
							<Button
								size="small"
								style={{
									float: "right",
									marginTop: "1.5rem",
									marginLeft: "1rem",
									marginRight: "0rem"
								}}
								disabled
								onClick={() => this.onEnrich()}
								disabled
							>
								<Icon type="sync" />
								<span>Enrich Data</span>
							</Button>
						)}
					</Col>
				</Row>
				<Row
					style={{
						background: "white",
						padding: "4rem",
						marginTop: "2rem 2rem",
						borderRadius: "4px"
					}}
				>
					<Col>
						<Row>
							<h2 style={{ fontWeight: "bold", marginLeft: "1rem" }}>
								{this.props.query.name ? this.props.query.name : null} Results{" "}
								<span
									style={{
										fontSize: "1.25rem",
										color: "#32b9ff",
										cursor: "pointer"
									}}
									onClick={() => this.goBack()}
								>
									<Icon type="edit" style={{ size: "1.75rem" }} /> Edit Search
								</span>
							</h2>
						</Row>
						<Row style={{ padding: 10 }}>
							<Col span={12}>
								<span style={{ fontWeight: "bold" }}>
									Viewing Top {this.props.results.length} Ranked Candidates
								</span>
							</Col>
							<Col span={12} />
						</Row>
						<Row>
							<Table
								dataSource={this.props.results}
								columns={columns}
								loading={hasStatus}
								size="small"
								rowClassName={(record, index) => {
									if (index % 2 > 0) {
										return "row_odd";
									} else {
										return "row__even";
									}
								}}
								pagination={{
									pageSize: this.state.pageSize,
									position: "bottom",
									showSizeChanger: true,
									onShowSizeChange: this.onShowSizeChange,
									pageSizeOptions: [ "25", "50", "75", "100" ]
								}}
								rowKey={(x) => x.id}
								rowSelection={rowSelection}
								scroll={{ y: 450 }}
							/>
						</Row>
						{this.state.profile !== null ? (
							<ResultsDrawer
								profile={this.state.profile}
								visible={this.state.visible}
								onClose={this.onClose.bind(this)}
								onMatch={this.checkMatch.bind(this)}
								onSelect={this.onProfileSelect.bind(this)}
								selectedRowKeys={this.state.selectedRowKeys}
								onSwitchProfile={this.switchProfile.bind(this)}
								width={600}
							/>
						) : null}
					</Col>
				</Row>
			</Row>
		);
	}
}

ResultsContainer.propTypes = {
	actions: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			workbookId: PropTypes.string.isRequired,
			queryId: PropTypes.string.isRequired
		})
	}),
	results: PropTypes.array.isRequired,
	resultSetId: PropTypes.string.isRequired,
	hasStatus: PropTypes.bool.isRequired,
	hasError: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		results: state.searchResults.results,
		resultSetId: state.searchResults.resultSetId,
		hasStatus: state.searchResults.status,
		hasError: state.searchResults.error,
		query: state.query,
		preview: state.searchResults.stats,
		searchID: state.searchResults.resultSetId
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		meta: bindActionCreators(meta, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsContainer);
