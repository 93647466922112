import React, { Component } from "react";
import { Row, Col, Divider, Input, Icon } from "antd";
import axios from "axios";
import * as api from "../../../constants/apiConstants";
import * as apiUtils from "../../../utils/apiUtils";
import n from "numeral";
import JobsFilter from "./jobsFilter";
import JobsResults from "./jobsResults";
import logo from "../../../images/itron-1-logo-svg-vector.svg";
const clientKey = "2f97dba9-e8a0-4872-947d-56632d4c8200";

class jobsContainer extends Component {
	state = {
		regions: [],
		countries: [],
		locations: [],
		region_filters: [],
		country_filters: [],
		location_filters: [],
		region_backup: [],
		country_backup: [],
		location_backup: [],
		reqs: [],
		reqs_backup: [],
		filters: [ "Search", "Region", "Local", "Country" ]
	};

	componentWillMount() {
		console.log("Trigger Mount");
		this.getAllCountries();
		this.postOpenJobs();
		this.getAllLocations();
		this.getAllRegions();
	}

	postOpenJobs() {
		axios
			.post(
				api.openJobs(clientKey),
				{
					regions: this.state.regions,
					countries: this.state.countries,
					locations: this.state.locations
				},
				apiUtils.getConfig()
			)
			.then((res) => {
				console.log(res.data);
				return this.setState({ reqs: res.data, reqs_backup: res.data });
			});
	}

	getAllCountries() {
		axios
			.get(api.allCountries(clientKey), apiUtils.getConfig())
			.then((res) => this.setState({ country_filters: res.data, country_backup: res.data }));
	}

	postAllCountries() {
		axios.post(api.allCountries(clientKey), {}, apiUtils.getConfig()).then((res) => console.log(res.data));
	}

	getAllLocations() {
		axios
			.get(api.allLocations(clientKey), apiUtils.getConfig())
			.then((res) => this.setState({ location_filters: res.data, location_backup: res.data }));
	}

	postAllLocations() {
		axios
			.post(
				api.allLocations(clientKey),
				{
					regions: this.state.regions,
					countries: this.state.countries
				},
				apiUtils.getConfig()
			)
			.then((res) => {
				this.setState({ location_filters: res.data, location_backup: res.data });
			});
	}

	getAllRegions() {
		axios
			.get(api.allRegions(clientKey), apiUtils.getConfig())
			.then((res) => this.setState({ region_filters: res.data, region_backup: res.data }));
	}

	postJobPosting(jobId) {
		axios
			.post(api.jobPosting(clientKey, "2790b497-9154-448a-8341-cc5d18d456d8"), {}, apiUtils.getConfig())
			.then((res) => {
				return res.data;
			});
	}

	postJobDetails() {
		const jobId = "2790b497-9154-448a-8341-cc5d18d456d8";
		const postId = "8fd8422f-9a2a-4fb8-b5c7-fff7250d6797";
		axios
			.post(api.jobPostingDetails(clientKey, jobId, postId), {}, apiUtils.getConfig())
			.then((res) => console.log(res.data));
	}

	postJobSearch() {
		const jobId = "2790b497-9154-448a-8341-cc5d18d456d8";
		const postId = "8fd8422f-9a2a-4fb8-b5c7-fff7250d6797";
		axios
			.post(api.jobSearch(clientKey, jobId, postId), {}, apiUtils.getConfig())
			.then((res) => console.log(res.data));
	}

	collapseFilter(label) {
		const index = this.state.filters.indexOf(label);
		const arr = this.state.filters;
		arr.splice(index, 1);
		this.setState({ filters: arr });
	}

	openFilter(label) {
		const arr = this.state.filters;
		arr.push(label);
		this.setState({ filters: arr });
	}

	updateFilter(label, data) {
		const arr = this.state[label];
		if (arr.indexOf(data) <= -1) {
			arr.push(data);
			this.setState({ [label]: arr }, function() {
				this.postOpenJobs();
				this.postAllLocations();
			});
		} else {
			const arr = this.state[label];
			const index = arr.indexOf(data);
			arr.splice(index, 1);
			this.setState({ [label]: arr }, function() {
				this.postOpenJobs();
				this.postAllLocations();
			});
		}
	}

	onSearchReqs(e) {
		const arr = [];
		console.log(e.target.value, e.target.value.length);
		if (e.target.value.length > 1) {
			this.state.reqs_backup.forEach((item, index) => {
				const lc_string = e.target.value.toLowerCase();
				if (item.title) {
					const lc_title = `${item.title.toLowerCase()}`;
					if (lc_title.includes(lc_string) === true) {
						console.log(item);
						arr.push(item);
					}
				}
			});
			this.setState({ reqs: arr });
		} else {
			this.setState({ reqs: this.state.reqs_backup });
		}
	}

	onSearchFilters(e, label) {
		const arr = [];
		let backup = `${label}_backup`;
		let filters = `${label}_filters`;
		if (e.target.value.length > 0) {
			this.state[backup].forEach((item, index) => {
				const lc_string = e.target.value.toLowerCase();
				if (item) {
					const lc = `${item.toLowerCase()}`;
					if (lc.includes(lc_string) === true) {
						console.log(item);
						arr.push(item);
					}
				}
			});
			this.setState({ [filters]: arr });
		} else {
			this.setState({ [filters]: this.state[backup] });
		}
	}

	render() {
		return (
			<Col
				style={{
					margin: "2rem 4rem",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column"
				}}
			>
				<Row
					style={{
						flexWrap: "wrap",
						background: "white",
						width: "100%",
						borderRadius: "5px 5px 0px 0px",
						padding: "0rem 3rem"
					}}
				>
					<img
						className="logo"
						src={logo}
						style={{
							height: "100px",
							paddingTop: "0rem",
							paddingBottom: "0rem"
						}}
					/>
				</Row>
				<Row
					style={{
						background: "#03a1b1",
						width: "100%",
						padding: "2rem 4rem"
					}}
				>
					<span style={{ fontSize: "2.5rem", color: "white", fontWeight: "bold" }}>Your AI4JOBs</span>
				</Row>

				<Row style={{ display: "flex", width: "100%", marginTop: "1rem" }}>
					<JobsFilter
						state={this.state}
						data={this.props}
						onSearch={this.onSearchReqs.bind(this)}
						openFilter={this.openFilter.bind(this)}
						onFilterSearch={this.onSearchFilters.bind(this)}
						collapseFilter={this.collapseFilter.bind(this)}
						onUpdateFilter={this.updateFilter.bind(this)}
					/>

					<JobsResults state={this.state} data={this.props} onGetJobId={this.postJobPosting.bind(this)} />
				</Row>
			</Col>
		);
	}
}

export default jobsContainer;
