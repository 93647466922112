import React, { Component } from "react";
import { Row, Col, Select, Affix, Icon, Popconfirm, message } from "antd";
import QueryExp from "./QueryExp";
const Option = Select.Option;
const text = "Would you like to remove these fields from your search?";

const labelStyle = {
	padding: "10px 10px 10px 10px",
	borderTopWidth: "1.02px",
	marginRight: "-5px",
	fontSize: "1.25rem",
	lineHeight: 1,
	color: "rgba(0, 0, 0, 0.45)",
	textAlign: "left",
	border: "none",
	borderBottomRightRadius: 0,
	borderTopRightRadius: 0,
	verticalAlign: "middle",
	borderRadius: "4px",
	position: "relative",
	transition: "all 0.3s",
	fontWeight: 600
};

class QueryTabPane extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		this.props.createBank();
	}
	componentWillReceiveProps() {}

	CreateChildren(type) {
		const arr = [];
		this.props.sharedState[type].map((term, index) => {
			arr.push(<Option key={term}>{term}</Option>);
		});
		return arr;
	}

	removeItems(key, index) {
		this.props.onRemoveFilter(key, index);
		message.success("Your search has been modified.");
	}

	render() {
		const data = this.props.data;
		console.log(this.props);
		const key = this.props.data.key;
		const filterIndex = this.props.filters.indexOf(data);
		return (
			<Row span={24} style={{ padding: 35 }}>
				{key !== "yearsExperience" ? (
					<Col span={23} style={{ marginBottom: "2rem" }}>
						<Popconfirm
							placement="topRight"
							title={text}
							onConfirm={() => this.removeItems(key, filterIndex)}
							okText="Yes"
							cancelText="No"
						>
							<Icon type="delete" theme="outlined" className="queryIcon__delete pull-right" />
						</Popconfirm>
					</Col>
				) : null}
				{key !== "yearsExperience" && key !== "location" && key !== "languages" ? (
					data.subCategories.map((subFilter, subFilterIndex) => (
						<Col span={11} key={subFilter.key + "-" + subFilterIndex} style={{ marginBottom: "4rem" }}>
							<Row>
								<h4 style={{ textTransform: "capitalize" }}>{subFilter.key.split("_").join(" ")}</h4>
							</Row>

							<Row style={{ marginTop: 10 }}>
								<label id="labelStyle" style={labelStyle}>
									Require
								</label>
								<Select
									mode="tags"
									key="mustHave"
									filter={filterIndex}
									value={subFilter["mustHave"]}
									placeholder="Enter filter criteria..."
									maxTagCount={1}
									allowClear={true}
									style={{ width: "70%" }}
									onChange={(newValues) => {
										this.props.onAddFilter(key);
										this.props.onUpdateFilter(filterIndex, subFilterIndex, "mustHave", newValues);
									}}
								>
									{this.CreateChildren("mustHave")}
								</Select>
							</Row>
							<Row style={{ marginTop: 15 }}>
								<label id="labelStyle" style={labelStyle}>
									Include
								</label>
								<Select
									mode="tags"
									key="shouldHave"
									filter={filterIndex}
									value={subFilter["shouldHave"]}
									placeholder="Enter filter criteria..."
									maxTagCount={1}
									allowClear={true}
									style={{ width: "70%" }}
									onChange={(newValues) => {
										this.props.onAddFilter(key);
										this.props.onUpdateFilter(filterIndex, subFilterIndex, "shouldHave", newValues);
									}}
								>
									{this.CreateChildren("shouldHave")}
								</Select>
							</Row>
							<Row style={{ marginTop: 15 }}>
								<label id="labelStyle" style={labelStyle}>
									Exclude
								</label>
								<Select
									mode="tags"
									key="mustNotHave"
									filter={filterIndex}
									value={subFilter["mustNotHave"]}
									placeholder="Enter filter criteria..."
									maxTagCount={1}
									allowClear={true}
									style={{ width: "70%" }}
									onChange={(newValues) => {
										this.props.onAddFilter(key);
										this.props.onUpdateFilter(
											filterIndex,
											subFilterIndex,
											"mustNotHave",
											newValues
										);
									}}
								>
									{this.CreateChildren("mustNotHave")}
								</Select>
							</Row>
						</Col>
					))
				) : null}
				{key === "yearsExperience" ? (
					<QueryExp
						data={this.props.data}
						filters={this.props.filters}
						filterIndex={filterIndex}
						onAddFilter={this.props.onAddFilter}
						onUpdateFilter={this.props.onUpdateFilter}
						onRemoveFilter={this.props.onRemoveFilter}
					/>
				) : null}
				{key === "location" ? (
					data.subCategories.map((subFilter, index) => (
						<Col span={12} key={subFilter.key + "-" + index} style={{ marginBottom: "4rem" }}>
							<Row>
								<h4 style={{ textTransform: "capitalize" }}>
									{subFilter.key !== "postCode" ? subFilter.key.split("_").join(" ") : "Post Code"}
								</h4>
							</Row>
							{/* <Row style={{ width: "80%", marginTop: 5 }}>
								{subFilter.key === "location" ? (
									<span style={{ color: "#7e8c8d" }}>
										Search a specific city and state, state, or country (ex: Austin, TX).
									</span>
								) : (
									<span style={{ color: "#7e8c8d" }}>
										Expand your location search by designating the radius (ex: "10 miles").
									</span>
								)}
							</Row> */}
							<Row style={{ marginTop: 10 }}>
								<Select
									mode="tags"
									key="mustHave"
									filter={filterIndex}
									value={subFilter["mustHave"]}
									placeholder={
										subFilter.key === "location" ? (
											"Enter a location (ex: Austin, TX, United States, TX)"
										) : subFilter.key === "distance" ? (
											"Enter the radius of a location you'd like to search (ex: 10 miles)"
										) : (
											"Enter the post code you'd like to search"
										)
									}
									maxTagCount={1}
									allowClear={true}
									style={{ width: "80%" }}
									onChange={(newValues) => {
										this.props.onAddFilter(key);
										this.props.onUpdateFilter(filterIndex, index, "mustHave", newValues);
									}}
								>
									{this.CreateChildren("mustHave")}
								</Select>
							</Row>
						</Col>
					))
				) : null}

				{key === "languages" ? (
					<Col span={12} key={data.subCategories[0].key + "-" + 0} style={{ marginBottom: "4rem" }}>
						<Row>
							<h4 style={{ textTransform: "capitalize" }}>
								{data.subCategories[0].key.split("_").join(" ")}
							</h4>
						</Row>

						<Row style={{ marginTop: 10 }}>
							<Select
								mode="tags"
								key="mustHave"
								filter={filterIndex}
								value={data.subCategories[0]["mustHave"]}
								placeholder="Enter a language (ex: English)"
								maxTagCount={1}
								allowClear={true}
								style={{ width: "100%" }}
								onChange={(newValues) => {
									this.props.onAddFilter(key);
									this.props.onUpdateFilter(filterIndex, 0, "mustHave", newValues);
								}}
							>
								{this.CreateChildren("mustHave")}
							</Select>
						</Row>
					</Col>
				) : null}
			</Row>
		);
	}
}

export default QueryTabPane;
