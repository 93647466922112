import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/queryActions";
import * as layoutActions from "../../actions/queryLayoutActions";
import { QueryCategories } from "../QueryCategories";
import { QueryCards } from "../QueryCards";
import { QueryInformation } from "../QueryInformation";
import throttle from "lodash/throttle";
import QueryTabs from "../QueryTabs";
import { message } from "antd";

export class QueryContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasFetched: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		this.props.actions.getQuery(this.props.match.params.workbookId, this.props.match.params.queryId);

		this.props.actions.previewQuery(this.props.match.params.workbookId, this.props.match.params.queryId);
	}

	save = () => {
		this.props.actions.updateQuery(this.props.match.params.workbookId, this.props.query).then(() => {
			this.props.actions.previewQuery(this.props.match.params.workbookId, this.props.query.id);
		});
	};

	toggleQueryOptionsCollapse = () => {
		this.props.layoutActions.toggleQueryOptionsPanel(!this.props.layout.optionsCollapsed);
	};

	toggleQueryInformationCollapse = () => {
		this.props.layoutActions.toggleQueryInformationPanel(!this.props.layout.informationCollapsed);
	};

	addFilter = (filterKey) => {
		this.props.actions.addFilter(filterKey).then(() => {
			this.save();
		});
	};

	removeFilter = (filterKey, filterIndex) => {
		// Clear all subfilters
		var promises = [];
		this.props.query.query.search[filterIndex].subCategories.map((subFilter, subFilterIndex) => {
			promises.push(this.props.actions.updateSubFilter(filterIndex, subFilterIndex, "mustHave", []));
			promises.push(this.props.actions.updateSubFilter(filterIndex, subFilterIndex, "shouldHave", []));
			promises.push(this.props.actions.updateSubFilter(filterIndex, subFilterIndex, "mustNotHave", []));
		});

		Promise.all(promises).then(() => {
			// Remove the card
			this.props.actions.removeFilter(filterKey).then(() => {
				this.save();
			});
		});
	};

	updateFilter = (filterIndex, subFilterIndex, filterType, newValues) => {
		// if (newValues.length > 0) {
		//   if (newValues[0].includes(":") === false) {
		//     message.success(`Field Updated`);
		//   }
		// } else if (newValues.length === 0) {
		//   message.warning(`Field Removed.`);
		// }
		this.props.actions.updateSubFilter(filterIndex, subFilterIndex, filterType, newValues).then(() => {
			this.save();
		});
	};

	runSearch = () => {
		this.props.history.push({
			pathname: `/search/${this.props.match.params.workbookId}/query/${this.props.match.params.queryId}/results`,
			state: {
				search_name: this.props.query.name || null
			}
		});
	};

	render() {
		return this.props.query.query && this.props.query.query.search ? (
			<div
				className="query-container"
				style={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					minHeight: "85vh",
					borderLeft: "1px solid #e7e7e7",
					marginBottom: "1rem"
				}}
			>
				<QueryTabs
					preview={this.props.previewResults}
					query={this.props.query}
					cards={this.props.query.annotations.cards}
					filters={this.props.query.query.search}
					onRemoveFilter={this.removeFilter}
					onUpdateFilter={this.updateFilter}
					onAddFilter={this.addFilter}
					onRunSearch={this.runSearch}
				/>
			</div>
		) : null;
	}
}

{
	/* <QueryCategories
					cards={this.props.query.annotations.cards}
					onAddFilter={this.addFilter}
					isCollapsed={this.props.layout.optionsCollapsed}
					onToggleCollapse={this.toggleQueryOptionsCollapse}
				/> */
}

{
	/* <QueryCards
					cards={this.props.query.annotations.cards}
					filters={this.props.query.query.search}
					onRemoveFilter={this.removeFilter}
					onUpdateFilter={this.updateFilter}
        /> */
}

{
	/* <QueryInformation
isCollapsed={this.props.layout.informationCollapsed}
previewResults={this.props.previewResults}
onToggleCollapse={this.toggleQueryInformationCollapse}
onRunSearch={this.runSearch}
route={this.props.match.url}
/> */
}

QueryContainer.propTypes = {
	actions: PropTypes.object.isRequired,
	layoutActions: PropTypes.object.isRequired,
	layout: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			workbookId: PropTypes.string.isRequired,
			queryId: PropTypes.string.isRequired
		})
	}),
	history: ReactRouterPropTypes.history.isRequired,
	query: PropTypes.object.isRequired,
	previewResults: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		layout: state.queryLayout,
		query: state.query,
		previewResults: state.previewResults
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		layoutActions: bindActionCreators(layoutActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryContainer);
