import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Table, Button } from "antd";
import * as api from "../../../constants/apiConstants";
import * as apiUtils from "../../../utils/apiUtils";
import logo from "../../../images/itron-1-logo-svg-vector.svg";
import Excellent from "../../../images/ExecellentRelevancy.svg";
import Good from "../../../images/GoodRelevancy.svg";
import Okay from "../../../images/OkayRelevancy.svg";
import Poor from "../../../images/PoorRelevancy.svg";
import ProfileDrawer from "./ProfileDrawer";
import Axios from "axios";

const chartStyle = {
  width: "100%"
};

class applicantResults extends Component {
  state = {
    jobId: this.props.match.params.jobId,
    postId: this.props.match.params.postId,
    clientId: "2f97dba9-e8a0-4872-947d-56632d4c8200",
    pageSize: 10,
    title: null,
    reqId: null,
    selectedRowKeys: [],
    selectedItems: [],
    data: [],
    backup: [],
    stats: null,
    indirect: false,
    profile: null,
    visible: false
  };

  componentWillMount() {
    console.log(
      api.jobSearch(this.state.clientId, this.state.jobId, this.state.postId)
    );
    Axios.post(
      api.jobSearch(this.state.clientId, this.state.jobId, this.state.postId),
      {},
      apiUtils.getConfig()
    ).then(res => {
      const arr = [];
      arr.push(
        res.data.results.filter(x => {
          if (x.indirect === false) {
            console.log(x);
            arr.push(x);
          }
        })
      );
      this.setState({
        data: arr,
        stats: res.data.stats,
        backup: res.data.results
      });
    });

    Axios.post(
      api.jobPostingDetails(
        this.state.clientId,
        this.state.jobId,
        this.state.postId
      ),
      {},
      apiUtils.getConfig()
    ).then(res => {
      console.log(res.data);
      this.setState({ title: res.data.title });
    });
  }

  onClose() {
    if (this.state.visible === true) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }
  }

  onProfileSelect(id) {
    const index = this.state.selectedRowKeys.indexOf(id);
    const selected = this.state.selectedRowKeys;
    if (index === -1) {
      selected.push(id);
      this.setState({ selectedRowKeys: selected });
    } else if (index > -1) {
      selected.splice(index, 1);
      this.setState({ selectedRowKeys: selected });
    }
  }

  createProfile(data) {
    this.setState({ profile: data });
    if (this.state.visible === true) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }
  }

  switchProfile(tag) {
    if (tag === "next") {
      const index = this.props.data.indexOf(this.state.profile);
      if (index !== this.props.data.length - 1) {
        this.setState({ profile: this.props.data[index + 1] });
      }
    } else if (tag === "prev") {
      const index = this.props.data.indexOf(this.state.profile);
      if (index !== 0) {
        this.setState({ profile: this.props.data[index - 1] });
      }
    }
  }

  rateResults(value, label) {
    if (value < 10) {
      return <span className="stat__rating--poor">Meh</span>;
    } else if (value > 10 && value < 20) {
      return <span className="stat__rating--okay">Okay</span>;
    } else if (value > 20 && value < 50) {
      return <span className="stat__rating--good">Good</span>;
    } else if (value > 50) {
      return <span className="stat__rating--excellent">Excellent</span>;
    }
  }

  chartResults(value, label) {
    if (value < 10) {
      return <img src={Poor} style={chartStyle} />;
    } else if (value > 10 && value < 20) {
      return <img src={Okay} style={chartStyle} />;
    } else if (value > 20 && value < 50) {
      return <img src={Good} style={chartStyle} />;
    } else if (value > 50) {
      return <img src={Excellent} style={chartStyle} />;
    }
  }

  tableStatus() {
    if (this.state.indirect === false) {
      const arr = [];
      this.state.backup.filter(x => {
        if (x.indirect === true) {
          arr.push(x);
        }
      });
      this.setState({ indirect: true, data: this.state.backup });
    } else if (this.state.indirect === true) {
      const arr = [];
      this.state.backup.filter(x => {
        if (x.indirect === false) {
          arr.push(x);
        }
      });
      this.setState({ indirect: false, data: arr });
    }
  }

  onSelectChange(e) {
    const value = e[0];
    const index = this.state.selectedRowKeys.indexOf(value);
    const arr = this.state.selectedRowKeys;
  }

  render() {
    const columns = [
      {
        title: (
          <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            Applicant Name
          </span>
        ),
        render: x => {
          const fullName = `${x.firstName} ${x.lastName}`.toLowerCase();
          return (
            <span style={{ textTransform: "capitalize" }}>{fullName}</span>
          );
        }
      },
      {
        title: (
          <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Match</span>
        ),
        render: x => {
          return this.rateResults(x.relevancy);
        }
      },
      {
        title: (
          <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            Relevancy
          </span>
        ),
        render: x => {
          return this.chartResults(x.relevancy);
        }
      },
      {
        title: (
          <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            Career Length
          </span>
        ),
        align: "center",
        render: x => {
          return x.careerLength;
        }
      },
      {
        title: (
          <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            Job Count
          </span>
        ),
        align: "center",
        render: x => {
          return x.careerCount;
        }
      },
      {
        title: "",
        align: "center",
        render: x => {
          return (
            <span
              style={{
                color: "#92cbf5",
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => this.createProfile(x)}
            >
              View Profile
            </span>
          );
        }
      }
    ];
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: e => this.onSelectChange(e)
    };

    return (
      <Row style={{ margin: "2rem 4rem" }}>
        <Row
          style={{
            flexWrap: "wrap",
            background: "white",
            width: "100%",
            borderRadius: "5px 5px 0px 0px",
            padding: "0rem 3rem"
          }}
        >
          <img
            className="logo"
            src={logo}
            style={{
              height: "100px",
              paddingTop: "0rem",
              paddingBottom: "0rem"
            }}
          />
        </Row>
        <Row
          style={{
            background: "#03a1b1",
            width: "100%",
            padding: "2rem 4rem",
            marginBottom: "1rem"
          }}
        >
          <span
            style={{ fontSize: "2.5rem", color: "white", fontWeight: "bold" }}
          >
            {this.state.title}
          </span>
          {this.state.indirect === false ? (
            <Button
              style={{ float: "right", marginTop: 5 }}
              onClick={() => this.tableStatus()}
            >
              Rank Indirect Applicants
            </Button>
          ) : (
            <Button
              style={{ float: "right", marginTop: 5 }}
              onClick={() => this.tableStatus()}
            >
              Rank Direct Applicants
            </Button>
          )}
        </Row>
        <Row
          style={{
            borderRadius: "4px",
            padding: "2rem 4rem",
            background: "white"
          }}
        >
          <Row style={{ marginBottom: "1rem" }}>
            <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
              Matching Results ({this.state.data.length})
            </span>
          </Row>
          <Row>
            <Table
              dataSource={this.state.data}
              columns={columns}
              pagination={{
                pageSize: this.state.pageSize,
                position: "bottom",
                showSizeChanger: true,
                onShowSizeChange: this.onShowSizeChange,
                pageSizeOptions: ["25", "50", "75", "100"]
              }}
              rowKey={x => x.id}
              rowSelection={rowSelection}
              scroll={{ y: 500 }}
            />
            {this.state.profile !== null ? (
              <ProfileDrawer
                profile={this.state.profile}
                visible={this.state.visible}
                onClose={this.onClose.bind(this)}
                onSelect={this.onProfileSelect.bind(this)}
                selectedRowKeys={this.state.selectedRowKeys}
                onSwitchProfile={this.switchProfile.bind(this)}
                width={600}
              />
            ) : null}
          </Row>
        </Row>
      </Row>
    );
  }
}

export default withRouter(applicantResults);
