import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/workbookActions";
import * as formActions from "../../actions/formActions";
import { Workbook } from "../Workbook";
import { Template } from "../Template";
import m from "moment";
import { WorkbookFormModal } from "../WorkbookFormModal";
import { MoveFormModal } from "../MoveFormModal";
import { QueryFormModal } from "../QueryFormModal";
import { DuplicateFormModal } from "../DuplicateFormModal";
import { Row, Col, Button, Icon, List, message, icon, Spin, Divider, Input } from "antd";
import NoResultsImage from "../../images/DISCOVER_IllustrationUpdate.svg";
import OnboardForm from "../OnboardForm";
import Column from "antd/lib/table/Column";
import { ShareFormModal } from "../ShareFormModal";
import ShareQueryForm from "../ShareQuery";

import QueryView from "../QueryView";

const listInactive = { paddingLeft: 10 };
const listActive = { borderRightColor: "#32b9ff", borderRightStyle: "solid", borderRightWidth: "2px", paddingLeft: 10 };

export class WorkbooksContainer extends React.Component {
	state = {
		templates: false,
		onboarding: false,
		entry: [],
		wb_search: false,
		currentView: this.props.workbooks[0],
		data: [],
		listSelected: null
	};

	componentDidMount() {
		this.props.actions.getWorkbooks(this.state.templates);

		const arr = [];

		for (var i = 0; i < 20; i++) {
			let wb = {
				id: null,
				name: `Workbook ${i}`,
				description: `Workbook Desc ${i}`
			};
		}

		if (this.props.workbooks.length > 0) {
			const arr = [];
			arr.push(this.props.workbooks[0]);
			this.setState({ entry: arr, data: this.props.workbooks });
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.workbooks.length > 0) {
			const index = this.props.workbooks.indexOf(this.state.entry[0]);
			const arr = [];
			arr.push(nextProps.workbooks[index]);
			this.setState({ currentView: nextProps.workbooks[index] });
			this.setState({ entry: arr });
		}
	}

	saveWorkbook = (workbook) => {
		if (workbook.id) {
			this.props.actions.updateWorkbook(workbook);
		} else {
			this.props.actions.createWorkbook(workbook);
		}

		this.closeWorkbookForm();
	};

	addWorkbook = () => {
		let newWorkbook = {
			id: null,
			name: "New Workbook",
			description: "New workbook description"
		};
		// if (this.props.workbooks.length < 1) {
		// 	this.setState({currentView:{
		// 		name: null,
		// 		queries: []
		// 	} })
		// }
		this.props.formActions.updateCurrentWorkbook(newWorkbook);
	};

	editWorkbook = (workbook) => {
		this.props.formActions.updateCurrentWorkbook(workbook);
	};

	closeWorkbookForm = () => {
		this.props.formActions.updateCurrentWorkbook(null);
	};

	removeWorkbook = (workbook) => {
		this.props.actions.deleteWorkbook(workbook);
	};

	saveQuery = (workbook, query) => {
		if (query.id) {
			this.props.actions.updateQuery(workbook, query);
		} else {
			this.props.actions.createQuery(workbook, query);
		}

		this.closeQueryForm();
	};

	addQuery = (workbook) => {
		let newQuery = {
			id: null,
			name: "New Query",
			description: "New query description",
			template: this.state.template,
			query: {},
			annotations: {}
		};

		this.props.formActions.updateCurrentQuery(workbook, newQuery);
	};

	editQuery = (workbook, query) => {
		this.props.formActions.updateCurrentQuery(workbook, query);
	};

	closeQueryForm = () => {
		this.props.formActions.updateCurrentQuery(null, null);
	};

	openQuery = (workbook, query) => {
		var type = "query";
		if (this.state.templates === false) {
			this.props.history.push(`/workbook/${workbook.id}/${type}/${query.id}`);
		} else if (this.state.templates === true) {
			type = "template";
			this.props.history.push(`/workbook/${workbook.id}/${type}/${query.id}`);
		}
	};

	removeQuery = (workbook, query) => {
		this.props.actions.deleteQuery(workbook, query);
	};

	openDuplicateModal = (workbook, query) => {
		this.props.formActions.updateDuplicateModal(workbook, query);
	};

	openMoveModal = (workbook, data) => {
		this.props.formActions.updateMoveModal(workbook, data, this.state.templates);
	};

	openShareModal = (workbook, query) => {
		this.props.formActions.updateShareModal(true, workbook, query);
	};

	openShareQueryModal = (workbooks, query) => {
		this.props.formActions.updateShareQueryModal(true, workbooks, query);
	};

	closeDuplicateForm = () => {
		this.props.formActions.updateDuplicateModal(null, null);
	};

	closeMoveForm = () => {
		this.props.formActions.updateMoveModal(null, null);
	};

	closeShareForm = () => {
		this.props.formActions.updateShareModal(false);
	};

	saveDuplicate = (queries, workbook) => {
		if (workbook === null) {
			workbook = queries;
			this.props.actions.duplicateWorkbook(workbook);
		} else if (workbook !== null) {
			this.props.actions.duplicateQuery(queries, workbook);
		}
		this.closeDuplicateForm();
	};

	saveMove = (queries, oldWorkbook, workbook, type, copy) => {
		if (type === "move") {
			queries.forEach((item) => {
				this.props.actions.moveQuery(item, oldWorkbook, workbook, type);
			});
		} else if (type === "copy") {
			copy.forEach((item) => {
				this.props.actions.copyQuery(item.id, oldWorkbook, workbook, type, item.name, item.description);
			});
		}
		this.closeMoveForm();
	};

	saveShare = (team, workbooks) => {
		workbooks.forEach((item) => {
			this.props.actions.shareWorkbook(team, item.key, item.label);
		});
		this.closeShareForm();
	};

	duplicateQuery = (workbook, query) => {
		this.props.actions.duplicateQuery(workbook, query);
	};

	duplicateWorkbook = (workbook) => {
		this.props.actions.duplicateWorkbook(workbook);
	};

	UpdateView = () => {
		if (this.state.templates === false) {
			var templates = false;

			this.props.workbooks.map((item) => {
				if (item.name === "My Templates") {
					templates = true;
				}
			});
			if (templates === true) {
				this.setState({ templates: true });
			} else {
				const MyTemplates = {
					id: null,
					name: "My Templates",
					description: "This is your templates workbook"
				};
				this.props.actions.createWorkbook(MyTemplates);
				this.setState({ templates: true });
			}
		} else {
			this.setState({ templates: false });
		}
	};

	saveShareQuery = (workbook, query, team) => {
		this.props.actions.shareQuery(workbook, query, team);
		this.props.formActions.updateShareQueryModal(false, workbook, query);
	};

	closeShareQuery = (status, workbook, query) => {
		this.props.formActions.updateShareQueryModal(status, workbook, query);
	};

	changeView(workbook) {
		const arr = [];
		arr.push(workbook);
		this.setState({ entry: arr, currentView: workbook, listSelected: workbook.id });
	}

	showSearch() {
		if (this.state.wb_search === false) {
			this.setState({ wb_search: true });
		} else {
			this.setState({ wb_search: false });
		}
	}

	onListSelect(key) {
		this.setState({ listSelected: key });
	}

	render() {
		return (
			<Row style={{ margin: "2rem 2rem 0rem 2rem", minHeight: "85vh" }}>
				<WorkbookFormModal
					data={this.props.workbooks}
					workbook={this.props.currentWorkbook}
					onSave={this.saveWorkbook.bind(this)}
					onCancel={this.closeWorkbookForm.bind(this)}
				/>

				<QueryFormModal
					workbookQuery={this.props.currentQuery}
					onSave={this.saveQuery.bind(this)}
					onCancel={this.closeQueryForm.bind(this)}
				/>

				<DuplicateFormModal
					duplicate={this.props.duplicateModal}
					onSave={this.saveDuplicate.bind(this)}
					onCancel={this.closeDuplicateForm.bind(this)}
				/>
				<MoveFormModal
					move={this.props.moveModal}
					onSave={this.saveMove.bind(this)}
					onCancel={this.closeMoveForm.bind(this)}
				/>

				<ShareFormModal
					team={this.props.user.team}
					workbooks={this.props.workbooks}
					share={this.props.shareModal}
					onSave={this.saveShare.bind(this)}
					onCancel={this.closeShareForm.bind(this)}
				/>

				<ShareQueryForm
					team={this.props.user.team}
					share={this.props.shareQuery}
					onSave={this.saveShareQuery.bind(this)}
					onCancel={this.closeShareQuery.bind(this)}
				/>
				<Row style={{ marginLeft: "2rem", background: "#32b9ff" }}>
					<Col span={5} style={{ paddingLeft: 40, paddingTop: 15 }}>
						<h3 style={{ color: "white", fontWeight: "bold" }}>DISCOVER</h3>
					</Col>
					<Col span={19}>
						<Button
							type="plus"
							size="small"
							style={{ marginTop: "1.5rem", float: "right", marginRight: "1rem" }}
							onClick={this.addWorkbook}
						>
							<Icon type="plus" /> Create Workbook
						</Button>
					</Col>
				</Row>
				<Row>
					<Col style={{ marginLeft: "2rem", marginTop: "1rem", marginBottom: "2rem" }}>
						<Col
							span={6}
							style={{
								height: "100%",
								display: this.props.workbooks.length > 0 ? "inherit" : "flex",
								minHeight: "632px",
								background: this.props.workbooks.length > 0 ? "white" : "",
								borderRight: "1px solid #e7e7e7",
								borderBottom: "1px solid #e7e7e7",
								borderRadius: 4,
								boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)"
							}}
						>
							{this.props.workbooks.length < 1 ? (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										alignSelf: "center",
										justifyContent: "center",
										alignText: "center"
									}}
								>
									<span style={{ marginLeft: "2rem", fontSize: "1.75rem", textAlign: "center" }}>
										Your Workbooks Will Be Displayed Here
									</span>
								</div>
							) : null}
							{this.props.workbooks.length > 0 ? (
								<Row>
									<Row style={{ padding: "20px 0px 0px 0px " }}>
										<Row style={{ fontWeight: "bold" }}>
											<Col span={18}>
												{this.props.workbooks.length > 0 ? (
													<h4 style={{ fontWeight: "bold", paddingLeft: 10 }}>Workbooks</h4>
												) : null}
											</Col>
											<Col span={6}>
												{/* <Icon
													onClick={(e) => this.showSearch()}
													type="search"
													style={{
														float: "right",
														marginRight: "1rem",
														paddingTop: ".25rem"
													}}
												/> */}
											</Col>
											<Divider style={{ marginTop: 5, marginBottom: 0, paddingRight: 2 }} />
										</Row>
										<Row>{this.state.wb_search === true ? <Input /> : null}</Row>
									</Row>

									<Row>
										{this.props.workbooks.length > 0 ? (
											<List
												dataSource={this.props.workbooks}
												pagination={{
													pageSize: 7,
													simple: true,
													size: "small"
												}}
												renderItem={(item) => (
													<List.Item
														key={item.id}
														onClick={(e) => this.changeView(item)}
														style={
															this.state.listSelected === item.id ? (
																listActive
															) : (
																listInactive
															)
														}
													>
														<List.Item.Meta
															title={item.name}
															description={
																<span style={{ fontSize: "1.2rem" }}>
																	{item.description}
																</span>
															}
														/>
													</List.Item>
												)}
											/>
										) : null}
									</Row>
								</Row>
							) : null}
						</Col>
						<Col
							span={18}
							style={{
								height: "100%",
								paddingLeft: 10
							}}
						>
							<QueryView
								noResults={NoResultsImage}
								templates={this.state.templates}
								workbooks={this.props.workbooks}
								onCreateWorkbook={this.addWorkbook.bind(this)}
								currentView={this.state.currentView}
								onShareWorkbook={this.openShareModal.bind(this)}
								onEditWorkbook={this.editWorkbook.bind(this)}
								onDuplicateWorkbook={this.openDuplicateModal.bind(this)}
								onDeleteWorkbook={this.removeWorkbook.bind(this)}
								onAddQuery={this.addQuery.bind(this)}
								onOpenQuery={this.openQuery.bind(this)}
								onEditQuery={this.editQuery.bind(this)}
								onDuplicateQuery={this.openDuplicateModal.bind(this)}
								onDeleteQuery={this.removeQuery.bind(this)}
								onMoveQuery={this.openMoveModal.bind(this)}
								onShareQuery={this.openShareQueryModal.bind(this)}
							/>
						</Col>
					</Col>
				</Row>
			</Row>
		);
	}
}

WorkbooksContainer.propTypes = {
	actions: PropTypes.object.isRequired,
	formActions: PropTypes.object.isRequired,
	history: ReactRouterPropTypes.history.isRequired,
	workbooks: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentWorkbook: PropTypes.object,
	currentQuery: PropTypes.object,
	duplicateModal: PropTypes.object,
	moveModal: PropTypes.object,

	shareModal: PropTypes.object,
	shareQuery: PropTypes.object
};

function mapStateToProps(state) {
	return {
		workbooks: state.workbooks,
		currentWorkbook: state.forms.currentWorkbook,
		currentQuery: state.forms.currentQuery,
		duplicateModal: state.forms.duplicateModal,
		moveModal: state.forms.moveModal,
		shareModal: state.forms.shareModal,

		shareQuery: state.forms.shareQuery,
		user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		formActions: bindActionCreators(formActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkbooksContainer);
