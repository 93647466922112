import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Divider,
  Input,
  Radio,
  InputNumber,
  Icon,
  message
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import * as actions from "../../actions/userActions";

const { Option } = Select;

class GreenhouseUser extends Component {
  state = {
    user: false,
    index: null,
    options: ["strict"],
    choices: ["strict", "exact", "title", "summary", "pool"],
    selector: true,
    enabledError: false
  };

  /* 
Strict has options 1, 2, 3

Exact has ON and OFF

Summary has ON and OFF

Title has HINT and FORCE (hint includes, force requires)

Pool size is for setting the smallest pool. This allows reveal to use an Algo that is more suited
for smaller pool sizes versus larger pool sizes.
*/

  componentWillMount() {
    this.props.actions.getGreenhouseClients();
  }

  onChange(value) {
    // Do something on select
    const { greenhouse } = this.props;
    const id = greenhouse.clients[value].clientKey;
    this.props.actions.getSingleGreenhouseClient(id).then(() => {
      this.props.actions.getGreenhouseOptions(id).then(() => {});
      if (this.state.user === true) {
        this.setState({ user: false, index: -1 });
      } else if (this.state.user === false) {
        this.setState({ user: true, index: value });
      }
    });
  }

  onNewUser() {
    this.props.actions.getGreenhouseClients();

    if (this.state.user === true) {
      this.setState({ user: false, index: -1 });
    }
  }

  onBlur() {
    // Do something on blur
  }

  onFocus() {
    // Do something on click
  }

  onSearch(val) {
    // Do something on while typing
  }

  onRadioChange(e, optionName) {
    if (e.target.name === "STRICTNESS") {
      const key = this.props.greenhouse.clientKey;
      this.setState({ strict: e.target.value });

      let data = {
        key: key,
        name: e.target.name,
        value: e.target.value
      };

      this.props.actions.createGreenhouseOption(data).then(() => {
        this.props.actions.getGreenhouseOptions(key).then(() => {});
      });
    } else if (e.target.name === "EXACT") {
      const key = this.props.greenhouse.clientKey;
      this.setState({ exact: e.target.value });

      let data = {
        key: key,
        name: e.target.name,
        value: e.target.value
      };

      this.props.actions.createGreenhouseOption(data).then(() => {
        this.props.actions.getGreenhouseOptions(key).then(() => {});
      });
    } else if (e.target.name === "SUMMARY") {
      const key = this.props.greenhouse.clientKey;
      this.setState({ summary: e.target.value });

      let data = {
        key: key,
        name: e.target.name,
        value: e.target.value
      };

      this.props.actions.createGreenhouseOption(data).then(() => {
        this.props.actions.getGreenhouseOptions(key).then(() => {});
      });
    } else if (e.target.name === "TITLE") {
      const key = this.props.greenhouse.clientKey;
      this.setState({ title: e.target.value });

      let data = {
        key: key,
        name: e.target.name,
        value: e.target.value
      };

      this.props.actions.createGreenhouseOption(data).then(() => {
        this.props.actions.getGreenhouseOptions(key).then(() => {});
      });
    }
  }

  onPoolChange(e, optionName) {
    const key = this.props.greenhouse.clientKey;
    this.setState({ pool: e.target.value });
    console.log(optionName);
    let data = {
      key: key,
      name: optionName,
      value: e.target.value
    };

    this.props.actions.createGreenhouseOption(data).then(() => {
      this.props.actions.getGreenhouseOptions(key).then(() => {});
    });
  }

  onDeleteOption(value) {
    const key = this.props.greenhouse.clientKey;
    const name = value;

    this.props.actions.deleteGreenhouseOption(key, name).then(() => {
      this.props.actions.getGreenhouseOptions(key);
    });
  }

  onDisableCustomer(value) {
    console.log(this.props, this.state);
    this.props.actions.DisableGreenhouseCustomer(value).then(res => {
      this.setState({ user: false }, function() {
        message.loading("Disabling user", 1).then(() => {
          message.success("User has been disabled");
          this.props.actions.getGreenhouseClients();
        });
      });
    });
  }

  navNewUser() {
    this.props.history.push("/reveal/create");
  }

  onUpdateClient(e, label) {
    console.log(e, label);
    const createRequest = {};
    const { clientKey, atsApiKey, name } = this.props.greenhouse;
    if (this.props.clientEmails) {
      createRequest["clientEmails"] = this.props.clientEmails;
    } else {
      createRequest["clientContactEmails"] = "support@thiswayglobal.com";
    }
    createRequest["name"] = name;
    createRequest["atsApiKey"] = atsApiKey;
    createRequest["atsAdapterType"] = "GHIO";

    if (label === "enabled") {
      if (e === 1) {
        console.log("enabled", e, true);
        createRequest["performBackFill"] = true;
        createRequest["reScoreOnly"] = true;
        createRequest["discoverAtsOnBehalfOf"] = true;
        createRequest["estimateOnly"] = true;
      } else if (e === 2) {
        console.log("enabled", e, false);
        createRequest["performBackFill"] = false;
        createRequest["reScoreOnly"] = false;
        createRequest["discoverAtsOnBehalfOf"] = false;
        createRequest["estimateOnly"] = false;
      }
    } else {
      createRequest[label] = e.target.value;
    }

    console.log(createRequest);
    this.props.actions.updateGreenhouseClient(createRequest, clientKey);
  }

  render() {
    const { greenhouse } = this.props;
    const { clients } = this.props.greenhouse;
    const radioStyle = {
      height: "30px",
      lineHeight: "30px"
    };
    return (
      <Row style={{ minHeight: "90vh", padding: "2rem" }}>
        {this.state.user === true ? (
          <Row style={{ margin: 4, height: "80vh", marginBottom: "2rem" }}>
            <Col
              span={14}
              style={{
                backgroundColor: "white",
                padding: 10,
                margin: "1rem",
                borderRadius: 5,
                minHeight: "100%"
              }}
            >
              <Row>
                <h4 style={{ marginTop: "1rem" }}>Client Info </h4>

                <Divider style={{ marginTop: "2rem", marginBottom: "1rem" }} />
                <Row>
                  <Row>
                    <label>Company</label>:{" "}
                    <Input
                      style={{ marginTop: ".5rem" }}
                      defaultValue={greenhouse.name}
                      onChange={e => this.onUpdateClient(e, "name")}
                    />
                  </Row>
                  <Row style={{ marginTop: "2rem" }}>
                    <label>clientKey</label>:{" "}
                    <Input
                      style={{ marginTop: ".5rem" }}
                      onChange={e => this.onUpdateClient(e, "clientKey")}
                      defaultValue={greenhouse.clientKey}
                    />
                  </Row>
                  <Row style={{ marginTop: "2rem" }}>
                    <label>clientSecret1</label>:{" "}
                    <Input
                      style={{ marginTop: ".5rem" }}
                      onChange={e => this.onUpdateClient(e, "clientSecret1")}
                      defaultValue={greenhouse.clientSecret1}
                    />
                  </Row>
                  <Row style={{ marginTop: "2rem" }}>
                    <label>clientSecret2</label>:{" "}
                    <Input
                      style={{ marginTop: ".5rem" }}
                      onChange={e => this.onUpdateClient(e, "clientSecret2")}
                      defaultValue={greenhouse.clientSecret2}
                    />
                  </Row>
                  <Row style={{ marginTop: "2rem" }}>
                    <label>ATS Key</label>:{" "}
                    <Input
                      style={{ marginTop: ".5rem" }}
                      onChange={e => this.onUpdateClient(e, "atsApiKey")}
                      defaultValue={greenhouse.atsApiKey}
                    />
                  </Row>
                  <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                    <label>enabled</label>:{" "}
                    <Select
                      defaultValue={
                        this.props.greenhouse.enabled === true ? 1 : 2
                      }
                      style={{ width: 120 }}
                      onChange={e => this.onUpdateClient(e, "enabled")}
                    >
                      <Option value={1}>True</Option>
                      <Option value={2}>False</Option>
                    </Select>
                  </Row>

                  {greenhouse.atsOnBehalfOf ? (
                    <Row style={{ marginTop: "2rem" }}>
                      <label>On Behalf</label>:{" "}
                      <Input
                        style={{ marginTop: ".5rem" }}
                        defaultValue={greenhouse.atsOnBehalfOf}
                      />
                    </Row>
                  ) : null}
                  <Row>
                    <Button
                      style={{ float: "right", marginTop: "4rem" }}
                      type="danger"
                      onClick={() =>
                        this.onDisableCustomer(this.props.greenhouse.clientKey)
                      }
                    >
                      Disable User
                    </Button>
                  </Row>
                </Row>
              </Row>
            </Col>
            <Col
              span={9}
              style={{
                backgroundColor: "white",
                padding: 10,
                margin: "1rem",
                borderRadius: 5,
                minHeight: "100%"
              }}
            >
              <Row>
                <h4 style={{ float: "left", marginTop: "1rem" }}>Options</h4>
                <Button
                  onClick={this.onNewUser.bind(this)}
                  style={{ float: "right", marginLeft: "1rem" }}
                >
                  Select Different User
                </Button>
              </Row>
              <Divider style={{ marginTop: 10, marginBottom: "1rem" }} />

              <Row>
                <label
                  style={{
                    fontWeight: "bold",
                    marginRight: "1rem",
                    marginBottom: "1rem"
                  }}
                >
                  Strictness:
                </label>{" "}
                <Radio.Group
                  onChange={e => this.onRadioChange(e)}
                  value={this.state.strict}
                  name="STRICTNESS"
                >
                  <Radio style={radioStyle} value={1}>
                    1
                  </Radio>
                  <Radio style={radioStyle} value={2}>
                    2
                  </Radio>
                  <Radio style={radioStyle} value={3}>
                    3
                  </Radio>
                </Radio.Group>
              </Row>
              <Row>
                <label
                  style={{
                    fontWeight: "bold",
                    marginRight: "1rem",
                    marginBottom: "1rem"
                  }}
                >
                  Exact:
                </label>{" "}
                <Radio.Group
                  onChange={e => this.onRadioChange(e)}
                  value={this.state.exact}
                  name="EXACT"
                >
                  <Radio style={radioStyle} value={"ON"}>
                    ON
                  </Radio>
                  <Radio style={radioStyle} value={"OFF"}>
                    OFF
                  </Radio>
                </Radio.Group>
              </Row>
              <Row>
                <label
                  style={{
                    fontWeight: "bold",
                    marginRight: "1rem",
                    marginBottom: "1rem"
                  }}
                >
                  Summary:
                </label>{" "}
                <Radio.Group
                  onChange={e => this.onRadioChange(e)}
                  value={this.state.summary}
                  name="SUMMARY"
                >
                  <Radio style={radioStyle} value={"ON"}>
                    ON
                  </Radio>
                  <Radio style={radioStyle} value={"OFF"}>
                    OFF
                  </Radio>
                </Radio.Group>
              </Row>
              <Row>
                <label
                  style={{
                    fontWeight: "bold",
                    marginRight: "1rem",
                    marginBottom: "1rem"
                  }}
                >
                  Title:
                </label>{" "}
                <Radio.Group
                  onChange={e => this.onRadioChange(e)}
                  value={this.state.title}
                  name="TITLE"
                >
                  <Radio style={radioStyle} value={"HINT"}>
                    Hint
                  </Radio>
                  <Radio style={radioStyle} value={"FORCE"}>
                    Force
                  </Radio>
                </Radio.Group>
              </Row>
              <Row>
                <label style={{ fontWeight: "bold", marginRight: "1rem" }}>
                  Pool Size:
                </label>{" "}
                <InputNumber
                  min={1}
                  max={1000}
                  onChange={e => this.onRadioChange(e, "POOL_SIZE")}
                />
              </Row>

              <Row>
                <Divider />
                <h4>Active Options</h4>
                <Divider />
                {this.props.options.length > 0
                  ? this.props.options.map((item, index) => {
                      const { optionName, optionValue } = item;

                      return (
                        <Row key={optionName} style={{ marginBottom: "1rem" }}>
                          <span style={{ display: "block" }}>
                            <label
                              style={{
                                textTransform: "capitalize",
                                color: "gray"
                              }}
                            >
                              {optionName}
                            </label>
                            : {optionValue}
                            <span style={{ float: "right" }}>
                              <Button
                                size="small"
                                onClick={() => this.onDeleteOption(optionName)}
                              >
                                Delete
                              </Button>
                            </span>
                          </span>
                        </Row>
                      );
                    })
                  : null}
              </Row>
            </Col>
          </Row>
        ) : (
          <Row style={{ margin: "2rem 5rem 2rem 5rem" }}>
            <Row>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a client"
                optionFilterProp="children"
                onChange={this.onChange.bind(this)}
                onFocus={this.onFocus.bind(this)}
                onBlur={this.onBlur.bind(this)}
                onSearch={this.onSearch.bind(this)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {clients
                  ? clients.map((item, index) => {
                      return (
                        <Option
                          key={clients.indexOf(item)}
                          value={clients.indexOf(item)}
                        >
                          {item.name}
                        </Option>
                      );
                    })
                  : null}
              </Select>

              <Button
                onClick={() => this.navNewUser()}
                style={{ float: "right" }}
              >
                Create User
              </Button>
            </Row>
          </Row>
        )}
      </Row>
    );
  }
}

GreenhouseUser.propTypes = {};

function mapStateToProps(state) {
  return {
    greenhouse: state.greenHouse,
    options: state.options,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GreenhouseUser)
);
