import React, { Component } from "react";
import { Row, Col, Divider, Input, Icon, Table } from "antd";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import * as api from "../../../constants/apiConstants";
import * as apiUtils from "../../../utils/apiUtils";
const clientKey = "2f97dba9-e8a0-4872-947d-56632d4c8200";

class JobsResults extends Component {
	state = {
		pageSize: 50
	};

	changeRoute(jobId) {
		axios.post(api.jobPosting(clientKey, jobId), {}, apiUtils.getConfig()).then((res) => {
			const postId = res.data[0].jobPostingExtId;
			console.log(res.data[0]);
			this.props.history.push(`/score/${jobId}/search/${postId}`);
		});
	}

	render() {
		const columns = [
			{
				title: <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Req. Number</span>,
				render: (x) => {
					return x.requestId;
				}
			},
			{
				title: <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Job Title</span>,
				render: (x) => {
					return x.title;
				}
			},
			{
				title: <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Location</span>,
				render: (x) => {
					return x.location;
				}
			},
			{
				title: <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Applicants</span>,
				align: "center",
				sortOrder: "ascend",
				sorter: (a, b) => b.applicantCount - a.applicantCount,
				render: (x) => {
					return x.applicantCount;
				}
			},
			{
				title: "",
				render: (x) => {
					return (
						<span style={{ color: "blue", cursor: "pointer" }} onClick={() => this.changeRoute(x.jobExtId)}>
							View
						</span>
					);
				}
			}
		];
		return (
			<Col
				id="result__column"
				style={{
					alignSelf: "center",
					justifyContent: "flex-start",
					flex: "0 0 80%",
					background: "white",
					borderRadius: 5,
					padding: "0rem 4rem",
					height: 650
				}}
			>
				<Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
					<span style={{ fontSize: "2.5rem", fontWeight: "bold" }}>Your Job Requisitions</span>
				</Row>
				<Row>
					<Table
						dataSource={this.props.state.reqs}
						columns={columns}
						size="default"
						rowKey={(x) => x.jobExtId}
						pagination={{
							pageSize: this.state.pageSize,
							position: "bottom",
							pageSizeOptions: [ "25", "50", "75", "100" ]
						}}
						scroll={{ y: 450 }}
					/>
				</Row>
			</Col>
		);
	}
}

export default withRouter(JobsResults);
