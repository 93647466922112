import React, { Component } from "react";
import { Input, Row, Col, Button, Checkbox, Progress, Divider, Spin, Icon } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import * as api from "../../constants/apiConstants";
import * as apiUtils from "../../utils/apiUtils";
import * as actions from "../../actions/userActions";

const formStyle = {
	marginBottom: "2rem",
	width: "90%"
};

// onBehalfDemo: "4409336002",
// id: "4351773002",
// key: "8f9f6346fdaecb4ad29b01bee6023f94-2",
// tip: "Establishing Connection"

const IND = <Icon className="animated__icon" type="loading" style={{ fontSize: 24, color: "#8492a2" }} spin />;
class GreenhouseKey extends Component {
	state = {
		name: null,
		email: null,
		key: null,
		error: false,
		gh: false,
		backfill: false,
		onBehalf: null,
		view: true,
		demo: true,
		fetch: null,
		onBehalfDemo: null,
		time: null,
		progress: null,
		id: null,
		key: null,
		tip: "Establishing Connection"
	};

	getKey() {
		const { name, email, key, backfill, onBehalf } = this.state;
		console.log(this.state);
		if (name !== null && email !== null && key !== null) {
			this.props.actions.getGreenhouseKey(name, email, key, backfill, onBehalf);
			this.setState({ error: false, gh: true, demo: false });
		} else {
			this.setState({ error: true, gh: false, demo: false });
		}
	}

	manageBackfill() {
		if (this.state.backfill === false) {
			this.setState({ backfill: true });
		} else {
			this.setState({ backfill: false });
		}
	}

	generateTips() {
		this.setState({ tip: "Establishing Connection" }, () => {
			for (let i = 0; i < 5; i++) {
				setTimeout(() => {
					if (this.state.loading === true && i < 5) {
						const phrases = [
							"Establishing Connection",
							"Looking up Job  ID",
							"Counting Applicants",
							"Matching Applicants",
							`Processing Applicants`
						];

						this.setState({ tip: phrases[i] });
					}
				}, i * 3000);
			}
		});
	}

	startValueFetch(key, id) {
		console.log(this.state);
		this.setState({ view: false, complete: false, loading: true }, () => {
			this.generateTips();

			axios
				.post(
					api.startValueDemo(key),
					{
						name: `30MIN VALUE - ${Math.floor(Math.random() * 1000) + 1}`,
						atsType: "GHIO",
						atsApiKey: key,
						atsOnBehalfOf: this.state.onBehalfDemo,
						jobId: id
					},
					apiUtils.getConfig()
				)
				.then((res) => {
					console.log("FIRST FETCH", res.data);
					this.setState({ fetch: res.data.status }, () => {
						const data = res.data.status;
						const { trackingId, clientKey, candidatesEstimated } = data;
						for (let i = 0; i < 1000; i++) {
							setTimeout(() => {
								if (this.state.loading === true) {
									axios
										.get(api.trackValueDemo(clientKey, trackingId), {
											headers: {
												Authorization: `Bearer ${localStorage.getItem("accessToken")}`
											}
										})
										.then((track) => {
											console.log("POLLING FETCH", track.data);
											const estTime = track.data.status.candidatesEstimated * 10;

											if (track.data.status.candidatesCompleted) {
												this.setState({
													tip: `${track.data.status.candidatesCompleted} of ${track.data
														.status.candidatesEstimated} Applicants Processed`
												});
											}

											if (track.data.status.candidatesCompleted) {
												if (
													track.data.status.candidatesCompleted ===
													track.data.status.candidatesEstimated
												) {
													this.setState({
														complete: true,
														fetch: status,
														loading: false,
														tip: "Applicant Matching Complete"
													});
												}
											}
										})
										.catch((e) => console.log(e));
								}
							}, i * 2000);
						}
					});
				})
				.catch((e) => console.log("ERROR", e.response));
			// Poll Refresh
			// Complete
		});
	}

	render() {
		return (
			<Row type="flex" align="middle" style={{ height: "100%", minHeight: "80vh" }}>
				{this.props.user.email.includes("thiswayglobal") === true ? (
					<Col span={24} style={{ margin: "0 0rem", padding: 20 }}>
						<Row type="flex" style={{ margin: "2rem" }}>
							{this.state.view === true && this.state.demo === true ? (
								<Col span={12}>
									<Col
										style={{
											background: "white",
											height: "100%",
											padding: 40,
											margin: 30,
											borderRadius: 5,
											boxShadow: "0px 0px 25px rgba(0,0,0,0.15)"
										}}
									>
										<Row>
											<h3 style={{ fontWeight: "bold" }}>Greenhouse Demo</h3>
										</Row>
										<Divider style={{ marginTop: "0rem" }} />
										<Row style={{ marginBottom: "2rem" }}>
											<Input
												style={{
													padding: "2rem .5rem",
													border: "none",
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)"
												}}
												placeholder="Greenhouse ATS Key"
												onChange={(e) => this.setState({ key: e.target.value })}
											/>
										</Row>
										<Row style={{ marginBottom: "2rem" }}>
											<Input
												style={{
													padding: "2rem .5rem",
													border: "none",
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)"
												}}
												placeholder="Greenhouse Job ID"
												onChange={(e) => this.setState({ id: e.target.value })}
											/>
										</Row>
										<Row style={{ marginBottom: "2rem" }}>
											<Input
												style={{
													padding: "2rem .5rem",
													border: "none",
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)"
												}}
												placeholder="Onbehalf ID"
												onChange={(e) => this.setState({ onBehalfDemo: e.target.value })}
											/>
										</Row>
										<Row>
											{this.state.key !== null &&
											this.state.id !== null &&
											this.state.onBehalfDemo !== null ? (
												<Button
													style={{ width: "100%" }}
													onClick={() => this.startValueFetch(this.state.key, this.state.id)}
												>
													Start
												</Button>
											) : null}
										</Row>
									</Col>
								</Col>
							) : null}
							{this.state.view === false ? (
								<Col
									span={12}
									style={{
										width: "100%",
										height: "80vh"
									}}
								>
									<Col
										span={24}
										style={{
											height: "100%",
											padding: 20,
											borderRadius: 5133
										}}
									>
										<Row className="example" type="flex" align="middle">
											<Spin spinning={this.state.loading} indicator={IND} />
											{this.state.loading === true ? (
												<Row type="flex" align="middle">
													<Row>
														<h2 className="heading__animation">{this.state.tip}</h2>
													</Row>{" "}
												</Row>
											) : (
												<Row>
													<h1>{this.state.tip}</h1>
													<Button
														ghost
														style={{ borderColor: "#8492a2", color: "#8492a2" }}
														onClick={() => this.setState({ view: true, fetch: null })}
													>
														New Job
													</Button>
												</Row>
											)}
										</Row>
									</Col>
								</Col>
							) : null}
							{this.state.view === true && this.state.demo === true ? (
								<Col span={12}>
									<Col
										style={{
											background: "white",
											height: "100%",
											padding: 40,
											margin: 30,
											borderRadius: 5,
											boxShadow: "0px 0px 25px rgba(0,0,0,0.15)"
										}}
									>
										<Row>
											<h3 style={{ fontWeight: "bold" }}>New Greenhouse Customer Setup</h3>
										</Row>
										<Divider style={{ marginTop: "0rem" }} />
										{this.state.error === true ? (
											<Row>
												<h4 style={{ weight: "bold", color: "red" }}>
													Please fill out all fields.
												</h4>
											</Row>
										) : null}
										<Row
											style={{
												marginTop: "1rem",
												marginBottom: "2rem",
												width: "90%"
											}}
										>
											<Input
												style={{
													padding: "2rem .5rem",
													border: "none",
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)"
												}}
												placeholder="Company Name"
												onChange={(e) => this.setState({ name: e.target.value })}
											/>
										</Row>
										<Row style={formStyle}>
											<Input
												style={{
													padding: "2rem .5rem",
													border: "none",
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)"
												}}
												placeholder="Company E-mail"
												onChange={(e) => this.setState({ email: e.target.value })}
											/>
										</Row>
										<Row style={formStyle}>
											<Input
												style={{
													padding: "2rem .5rem",
													border: "none",
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)"
												}}
												placeholder="Harvest API Key"
												onChange={(e) => this.setState({ key: e.target.value })}
											/>
										</Row>
										<Row style={formStyle}>
											<Input
												style={{
													padding: "2rem .5rem",
													border: "none",
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)"
												}}
												placeholder="On Behalf Of"
												onChange={(e) => this.setState({ onBehalf: e.target.value })}
											/>
										</Row>
										<Row style={formStyle}>
											<Checkbox onChange={(e) => this.manageBackfill()}>
												Backfill Enabled
											</Checkbox>
										</Row>
										<Row style={formStyle}>
											<Button style={{ width: "100%" }} onClick={(e) => this.getKey(this.state)}>
												Submit
											</Button>
										</Row>
									</Col>
								</Col>
							) : null}
							{this.state.gh === true ? (
								<Col
									span={12}
									style={{
										background: "white",
										height: "100%",
										padding: 40,
										margin: 30,
										borderRadius: 5,
										boxShadow: "0px 0px 25px rgba(0,0,0,0.15)"
									}}
								>
									<Row>
										<h3>Congrats, You've Created a Key</h3>
									</Row>
									<Row style={formStyle}>
										<span>
											<label style={{ fontWeight: "bold", marginRight: "1rem" }}>Name: </label>
											{this.props.greenhouse.name}
										</span>
									</Row>
									<Row style={formStyle}>
										<span>
											<label style={{ fontWeight: "bold", marginRight: "1rem" }}>
												Endpoint URL:{" "}
											</label>
											https://ghio.ai4jobs.me/twg/api/v1/ghio/
											{this.props.greenhouse.clientKey}
										</span>
									</Row>
									<Row style={formStyle}>
										<span>
											<label style={{ fontWeight: "bold", marginRight: "1rem" }}>
												Secret Key:{" "}
											</label>
											{this.props.greenhouse.clientSecret1}
										</span>
									</Row>
									<Row style={formStyle}>
										<span>
											<label style={{ fontWeight: "bold", marginRight: "1rem" }}>
												Backfill Enabled:{" "}
											</label>
											{this.props.greenhouse.enabled.toString()}
										</span>
									</Row>
									<Row style={formStyle}>
										<Button onClick={() => this.setState({ demo: true, gh: false })}>
											Create New User
										</Button>
									</Row>
								</Col>
							) : null}
						</Row>
					</Col>
				) : (
					<div style={{ margin: "2rem" }}>
						<h1>You are not an authorized user.</h1>
					</div>
				)}
			</Row>
		);
	}
}

GreenhouseKey.propTypes = {};

function mapStateToProps(state) {
	return {
		greenhouse: state.greenHouse,
		user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenhouseKey);
