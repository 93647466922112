export const QUERY_CATEGORIES = [
	{
		key: "industry",
		displayName: "Industry",
		icon: "ios-briefcase-outline",
		subCategoryDisplayName: {
			current_company: "Current Company",
			previous_company: "Previous Company",
			current_industry: "Current Industry",
			previous_industry: "Previous Industry"
		}
	},
	{
		key: "education",
		displayName: "Education",
		icon: "ios-school-outline",
		subCategoryDisplayName: {
			certifications: "Certifications",
			degree: "Degree",
			school: "School"
		}
	},
	{
		key: "location",
		displayName: "Location",
		icon: "ios-globe-outline",
		subCategoryDisplayName: {
			location: "Location",
			distance: "Distance",
			postCode: "Post Code"
		}
	},
	{
		key: "workBio",
		displayName: "Work Bio",
		icon: "ios-hammer-outline",
		subCategoryDisplayName: {
			expertise: "Expertise",
			summary: "Summary/Bio"
		}
	},
	{
		key: "jobTitles",
		displayName: "Job Titles",
		icon: "ios-people-outline",
		subCategoryDisplayName: {
			current_title: "Current Title",
			previous_title: "Previous Title"
		}
	},
	{
		key: "yearsExperience",
		displayName: "Years Experience",
		icon: "ios-calendar-outline",
		subCategoryDisplayName: {
			career_length: "Career Length",
			career_count: "Job Count"
		}
	},
	{
		key: "languages",
		displayName: "Languages",
		icon: "ios-chatboxes-outline",
		subCategoryDisplayName: {
			languages: "Languages"
		}
	}
];
