import React, { Component } from "react";
import { Row, Col, Divider, Input, Icon, Checkbox } from "antd";
import axios from "axios";
import * as api from "../../../constants/apiConstants";
import * as apiUtils from "../../../utils/apiUtils";
import n from "numeral";
import logo from "../../../images/ai4jobs-color-logo.png";
const clientKey = "2f97dba9-e8a0-4872-947d-56632d4c8200";

class JobsFilter extends Component {
  render() {
    console.log(this.props);
    return (
      <Col id="filter__column" style={{ width: "100%" }}>
        <Row
          style={{
            alignSelf: "center",
            justifyContent: "flex-start",
            flex: "0 0 20%",
            height: 500,
            marginRight: "1rem"
          }}
        >
          <Row>
            <Row style={{ background: "white", padding: 10, borderRadius: 5 }}>
              <Row>
                <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Job Req Search
                  {this.props.state.filters.indexOf("Search") > -1 ? (
                    <Icon
                      type="down"
                      onClick={() => this.props.collapseFilter("Search")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  ) : (
                    <Icon
                      type="up"
                      onClick={() => this.props.openFilter("Search")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  )}
                </span>
              </Row>
              {this.props.state.filters.indexOf("Search") > -1 ? (
                <Row>
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  />
                  <Input
                    placeholder="Search by ID or Title"
                    onChange={e => this.props.onSearch(e)}
                    suffix={<Icon type="search" style={{ color: "#d9d9d9" }} />}
                  />
                </Row>
              ) : null}
            </Row>
          </Row>
          <Row>
            <Row
              style={{
                background: "white",
                padding: 10,
                borderRadius: 5,
                marginTop: "1rem"
              }}
            >
              <Row>
                <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Country
                  {this.props.state.filters.indexOf("Country") > -1 ? (
                    <Icon
                      type="down"
                      onClick={() => this.props.collapseFilter("Country")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  ) : (
                    <Icon
                      type="up"
                      onClick={() => this.props.openFilter("Country")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  )}
                </span>
              </Row>
              {this.props.state.filters.indexOf("Country") > -1 ? (
                <Row>
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  />
                  {/* <Input
                    onChange={e => this.props.onFilterSearch(e, "country")}
                    placeholder="Search By Country"
                    size="small"
                    suffix={<Icon type="search" style={{ color: "#d9d9d9" }} />}
                  />
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  /> */}
                  <Row>
                    {" "}
                    {this.props.state.country_filters.map((item, index) => {
                      if (index < 3) {
                        return (
                          <Row>
                            <Checkbox
                              key={item}
                              value={item}
                              checked={
                                this.props.state.countries.indexOf(item) > -1
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                this.props.onUpdateFilter(
                                  "countries",
                                  e.target.value
                                );
                              }}
                            >
                              {item}
                            </Checkbox>
                          </Row>
                        );
                      }
                    })}
                  </Row>
                </Row>
              ) : null}
            </Row>
          </Row>
          <Row>
            <Row
              style={{
                background: "white",
                padding: 10,
                borderRadius: 5,
                marginTop: "1rem"
              }}
            >
              <Row>
                <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Region
                  {this.props.state.filters.indexOf("Region") > -1 ? (
                    <Icon
                      type="down"
                      onClick={() => this.props.collapseFilter("Region")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  ) : (
                    <Icon
                      type="up"
                      onClick={() => this.props.openFilter("Region")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  )}
                </span>
              </Row>
              {this.props.state.filters.indexOf("Region") > -1 ? (
                <Row>
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  />
                  {/* <Input
										placeholder="Search By Region"
										size="small"
										onChange={(e) => this.props.onFilterSearch(e, "region")}
										suffix={<Icon type="search" style={{ color: "#d9d9d9" }} />}
									/> */}
                  {/* <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  /> */}
                  <Row>
                    {" "}
                    {this.props.state.region_filters.map((item, index) => {
                      if (index < 6) {
                        return (
                          <Row>
                            <Checkbox
                              key={item}
                              value={item}
                              checked={
                                this.props.state.regions.indexOf(item) > -1
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                this.props.onUpdateFilter(
                                  "regions",
                                  e.target.value
                                );
                              }}
                            >
                              {item}
                            </Checkbox>
                          </Row>
                        );
                      }
                    })}
                  </Row>
                </Row>
              ) : null}
            </Row>
          </Row>
          <Row>
            <Row
              style={{
                background: "white",
                padding: 10,
                borderRadius: 5,
                marginTop: "1rem"
              }}
            >
              <Row>
                <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  City, State
                  {this.props.state.filters.indexOf("Local") > -1 ? (
                    <Icon
                      type="down"
                      onClick={() => this.props.collapseFilter("Local")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  ) : (
                    <Icon
                      type="up"
                      onClick={() => this.props.openFilter("Local")}
                      style={{
                        float: "right",
                        paddingTop: ".75rem",
                        fontSize: "10px",
                        marginRight: ".5rem"
                      }}
                    />
                  )}
                </span>
              </Row>
              {this.props.state.filters.indexOf("Local") > -1 ? (
                <Row>
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  />
                  <Input
                    placeholder="Search by City and State"
                    size="small"
                    onChange={e => this.props.onFilterSearch(e, "location")}
                    suffix={<Icon type="search" style={{ color: "#d9d9d9" }} />}
                  />
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  />
                  <Row>
                    {" "}
                    {this.props.state.location_filters.map((item, index) => {
                      if (index < 6) {
                        return (
                          <Row>
                            <Checkbox
                              key={item}
                              value={item}
                              checked={
                                this.props.state.locations.indexOf(item) > -1
                                  ? true
                                  : false
                              }
                              onChange={e => {
                                this.props.onUpdateFilter(
                                  "locations",
                                  e.target.value
                                );
                              }}
                            >
                              {item}
                            </Checkbox>
                          </Row>
                        );
                      }
                    })}
                  </Row>
                </Row>
              ) : null}
            </Row>
          </Row>
        </Row>
      </Col>
    );
  }
}

export default JobsFilter;
