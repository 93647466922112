import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/passwordActions";
import { Row, Col, Button, Form, Input, Icon, message } from "antd";
import { Image } from "react-bootstrap";
import logo from "../../images/ai4jobs-color-logo.png";

const FormItem = Form.Item;
export class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = { email: "" };
	}
	componentDidMount() {}

	onChangeEmail = (event) => {
		return this.setState({ email: event.target.value });
	};

	isValidEmail = (email) => {
		/* eslint no-useless-escape: "off" */
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(String(email).toLowerCase());
	};

	getValidationState() {
		if (this.isValidEmail(this.state.email)) {
			return null;
		} else {
			return "error";
		}
	}

	sendInstructions = (event) => {
		this.loadingMessage(this.state.email);
		event.preventDefault();
		this.props.form.validateFields((err) => {
			if (!err) {
				this.props.actions
					.sendResetInstructions(this.state.email)
					.then(() => this.successMessage(this.state.email))
					.catch(() => this.warningMessage(this.state.email));
			}
		});
	};

	warningMessage = (email) => {
		message.warning(`${email} could not be found!`);
	};

	successMessage = (email) => {
		message.success(`Please check ${email} for reset link`);
	};

	loadingMessage = (email) => {
		message.loading(`Checking for ${email}`);
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div className="forgot-password-page" style={{ marginBottom: "5rem", minHeight: "90vh!important" }}>
				<Row style={{ height: "80vh" }}>
					<Row type="flex" justify="center">
						<Col span={6} className="login-box">
							<Row type="flex" justify="center">
								<Col span={24} style={{ marginBottom: ".5rem" }}>
									<Image className="logo" src={logo} responsive />
								</Col>
							</Row>
							<Row type="flex" justify="center">
								<Col span={24} style={{ textAlign: "center", marginBottom: "1rem" }}>
									<h3 style={{ fontWeight: "bold" }}>Reset Your Password</h3>
								</Col>
							</Row>
							<Form onSubmit={(e) => this.sendInstructions(e)}>
								<FormItem>
									{getFieldDecorator("email", {
										rules: [ { required: true, message: "Please input your email!" } ]
									})(
										<Input
											type="email"
											style={{ paddingTop: "2px" }}
											placeholder="Email"
											name="email"
											prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
											onChange={this.onChangeEmail}
										/>
									)}
								</FormItem>
								<FormItem>
									{this.state.email.includes("@") ? (
										<Button
											className="button__style--login"
											style={{ width: "100%", marginBottom: "1rem" }}
											htmlType="submit"
										>
											Reset Your Password
										</Button>
									) : (
										<Button
											className="button__style--disabled"
											style={{ width: "100%", marginBottom: "1rem" }}
											htmlType="submit"
											disabled
										>
											Reset Your Password
										</Button>
									)}
								</FormItem>
								<Row type="flex" justify="center">
									<span style={{ justifySelf: "center", letterSpacing: ".05rem" }}>
										Go back to{" "}
										<Link to={`/login`} style={{ fontWeight: "bold" }}>
											AI4Jobs.me/login
										</Link>
									</span>
								</Row>
							</Form>
						</Col>
					</Row>
				</Row>
			</div>
		);
	}
}

ForgotPassword.propTypes = {
	actions: PropTypes.object.isRequired,
	passwordLink: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		passwordLink: state.passwordLink
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

const ForgotForm = Form.create()(ForgotPassword);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotForm);
